import React from 'react';

interface EducationItemProps {
  id: number
  title: string;
  url: string;
  onClick: (id: number) => void;
}

export const CertificationItem: React.FC<EducationItemProps> = ({
  id,
  title,
  url,
  onClick,
}) => (<li onClick={() => onClick(id)}>
  <figure className="w-48 h-36 flex justify-center items-center relative overflow-hidden border-gray-500 border-opacity-70 border rounded cursor-pointer hover:border-cyan-500 bg-gray-400 hover:bg-cyan-500">
    <img src={url} alt={title} />
    <figcaption className="absolute bottom-0 left-0 w-full bg-inherit p-2">{title}</figcaption>
  </figure>
</li>);
