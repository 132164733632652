import React from 'react';

const qualifications = [
  'JavaScript, TypeScript',
  'React, redux, react-query',
  'CSS, tailwind, styled-components',
  'PostgreSQL, No-SQL (MongoDB)',
  'NodeJS (Hapi/Express)',
  'HTML (HTML5, WAI-ARIA)',
  'Scrum (sprints, sprint planing, estimations)',
  'Code reviews, team management, task estimations, mentoring',
  'WebStorm, Idea, Git, Postman, Construct 2, Windows/Linux',
]

export const Qualifications = () => (<ul className="ml-10 list-disc">
  {qualifications.map(item => <li key={item}>{item}</li>)}
</ul>)