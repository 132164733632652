import React from 'react';
import { MdOutlineMail } from 'react-icons/md';
import { AiOutlineSkype, AiOutlineLinkedin } from 'react-icons/ai';
import { Title } from './common/title';

export const Header = () => (<header className="flex">
  <figure className="w-24 h-24 min-w-24 min-h-24 sm:w-40 sm:h-40 sm:min-w-40 sm:min-h-40 print:min-w-40 print:min-h-40 rounded-full overflow-hidden">
    <img src="./photo.jpg" alt="Oleksandr Lisovyk" />
  </figure>
  <div className="ml-4 sm:ml-8 flex-grow">
    <h1 className="border-b border-black text-5xl w-full pb-5">Oleksandr Lisovyk</h1>
    <div className="flex mt-5 justify-between">
      <Title className="hidden sm:block print:block">Contact</Title>
      <address className="text-sm not-italic">
        <p>
          <MdOutlineMail className="inline mr-2 text-base" />
          <a className="border-b border-black hover:border-cyan-500 hover:text-cyan-500 hover:print:border-black hover:print:text-black"
             href="mailto:lisovykoleksandr@outlook.com">lisovykoleksandr@outlook.com</a>
        </p>
        <p>
          <AiOutlineSkype className="inline mr-2 text-base" />
          <a className="border-b border-black hover:border-cyan-500 hover:text-cyan-500 hover:print:border-black hover:print:text-black"
             href="skype:live:lisovykoleksandr">live:lisovykoleksandr</a>
        </p>
        <p>
          <AiOutlineLinkedin className="inline mr-2 text-base" />
          <a className="border-b border-black hover:border-cyan-500 hover:text-cyan-500 hover:print:border-black hover:print:text-black"
             href="https://www.linkedin.com/in/oleksandr-lisovyk-5b078355/"
             target="_blank"
             rel="noreferrer">Oleksandr Lisovyk</a>
        </p>
      </address>
      <address className="text-sm not-italic hidden md:block print:block">
      <p>Honoré de Balzac St</p>
      <p>Kyiv, Kyiv City</p>
      <p>Ukraine</p>
      </address>
    </div>
</div>
</header>);
