import React, { useCallback, useState } from 'react';
import { CertificationItem } from './certificationItem';
import { ImageModal } from '../imageModal';

type Certification = {
  id: number,
  title: string
  url: string,
}

const certifications: Array<Certification> = [{
  id: 3,
  title: 'Microstrategy Architect',
  url: './img/micro-cert.jpg',
}, {
  id: 2,
  title: 'React Patterns & Features',
  url: './img/react-cert.jpg',
}, {
  id: 1,
  title: 'MongoDB for Node.js',
  url: './img/mongo-cert.jpg',
}];

export const Certifications = () => {
  const [selectedId, setSelectedId] = useState<null | number>(null);
  
  const setSelectedCallback = useCallback((newSelectedId: number | null) => {
    setSelectedId(selectedId === newSelectedId ? null : newSelectedId);
  }, [selectedId]);
  const selectedCertificate = certifications.find(item => item.id === selectedId);
  
  return <ul className="flex justify-between">
    {certifications.map(({
      id,
      title,
      url,
    }) => <CertificationItem
      id={id}
      key={id}
      url={url}
      title={title}
      onClick={setSelectedCallback} />)}
    <ImageModal
      isOpen={!!selectedId}
      onClick={() => setSelectedCallback(null)}
      imgUrl={selectedCertificate?.url}
      alt={selectedCertificate?.title} />
  </ul>;
};