import React from 'react';
import { formatDate } from '../../utils/date';

interface EducationItemProps {
  university: string,
  startDate: Date,
  endDate: Date,
  description: string;
}

export const EducationItem: React.FC<EducationItemProps> = ({
  university,
  startDate,
  endDate,
  description
}) => (<li className="mb-4 last-of-type:mb-0">
  <section>
    <h3 className="flex justify-between text-lg">
      <span className="block md:inline">{university}</span>
      <span className="text-base md:text-lg">
        {`${formatDate(startDate)} — ${formatDate(endDate)}`}
      </span>
    </h3>
    <h4 className="text-sm py-1">{description}</h4>
  </section>
</li>);
