import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

interface ImageModalProps {
  isOpen: boolean;
  onClick: () => void;
  imgUrl?: string;
  alt?: string;
}

export const ImageModal: React.FC<ImageModalProps> = ({
  isOpen,
  onClick,
  imgUrl = '',
  alt = '',
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }
  return ReactDOM.createPortal(<div
    onClick={onClick}
    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center cursor-pointer">
    <img className="max-h-[80vh] max-w-[80vw] select-none rounded" src={imgUrl} alt={alt} />
  </div>, document.getElementById('image') as HTMLElement);
};