import React from 'react';
import { EducationItem } from './educationItem';

type Education = {
  id: number,
  university: string,
  startDate: Date,
  endDate: Date,
  description: string;
}

const educations: Array<Education> = [{
  id: 3,
  university: 'Kyiv Polytechnic Institute',
  startDate: new Date(2012, 8, 1),
  endDate: new Date(2014, 0, 31),
  description: 'Specialist\'s degree in software engineering',
}, {
  id: 2,
  university: 'Kyiv Polytechnic Institute',
  startDate: new Date(2008, 8, 1),
  endDate: new Date(2012, 4, 31),
  description: 'Bachelor\'s degree in software engineering',
}, {
  id: 1,
  university: 'Technical Lyceum of Kyiv',
  startDate: new Date(2006, 8, 1),
  endDate: new Date(2008, 4, 31),
  description: 'High school diploma in software development basics',
}]

export const Educations = () => (<ul>
  {educations.map(({
    id,
    university,
    startDate,
    endDate,
    description
  }) => <EducationItem
    key={id}
    university={university}
    startDate={startDate}
    endDate={endDate}
    description={description} />)}
</ul>)