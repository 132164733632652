import React from 'react';
import { ExperienceItem } from './experienceItem';

type Experience = {
  id: number;
  title: string;
  position: string;
  startDate: Date;
  endDate?: Date;
  actions: Array<string>;
  technologies: Array<string>;
}

const experiences: Array<Experience> = [{
  id: 5,
  title: 'Actio.com, Self Employed',
  position: 'Senior Software Engineer',
  startDate: new Date(2021, 2, 1),
  endDate: new Date(2022, 6, 31),
  actions: [
    'Created and maintained project with full delivery cycle for self-service for business customers of Actio.com',
    'Set up the process of testing and deploying web portal',
    'Worked in a small team on smartphone application',
    'Was responsible for reviewing PRs, developing tests, fixing bugs, establishing codestyle and project infrastructure',
  ],
  technologies: ['React', 'TypeScript', 'redux', 'styled-components', 'tailwind', 'React Native', 'eslint', 'jest', 'react-testing-library', 'Istanbul', 'GitHub Actions'],
}, {
  id: 4,
  title: 'Totem.com, Self Employed',
  position: 'Senior Software Engineer',
  startDate: new Date(2020, 7, 1),
  endDate: new Date(2021, 2, 31),
  actions: [
    'Worked in a small team on developing widgets for web-portal',
    'Introduced integration with Google Maps',
    'Maintained project in proper condition',
    'Was reviewing PRs, developing tests, fixing bugs',
  ],
  technologies: ['TypeScript', 'React', 'redux', 'tailwind', 'Scala', 'scala.js', 'Google Maps API', 'jest', 'react-testing-library'],
}, {
  id: 3,
  title: 'MicroStrategy, Warsaw, Poland',
  position: 'Senior Software Engineer',
  startDate: new Date(2019, 1, 1),
  endDate: new Date(2020, 2, 31),
  actions: [
    'Managed team of 10 people, established cooperation between several teams worldwide',
    'Created several projects in BI field for end-customers as well as internal purposes',
    'Created infrastructure for projects, including testing infrastructure, build, participated in setting-up CI pipeline via Jenkins',
    'Was responsible for code standards, maintainability and readability of code',
    'Developed components library, used in different projects',
    'Set up internal tools such as bug-tracking systems, as well as standalone servers for projects',
  ],
  technologies: ['JavaScript', 'React', 'redux', 'styled-components', 'StoryBook', 'npm', 'Jenkins', 'jest', 'react-testing-library', 'Istanbul', 'eslint'],
}, {
  id: 2,
  title: 'EPAM Systems, Krakow, Poland',
  position: 'Software Engineer',
  startDate: new Date(2014, 8, 1),
  endDate: new Date(2019, 0, 31),
  actions: [
    'Wrote maintainable and extensible code in a team environment',
    'Worked closely with design, DevOps and QA teams to achieve high-quality application with full delivery cycle',
    'Modified existing software to fix errors, upgrade interfaces and improve performance',
    'Consulted regularly with customers on project status, proposals and technical issues',
    'Interfaced with business analysts, developers and technical support to determine the best requirement specifications',
    'Worked in Scrum team, participated in task estimations, sprint planning, daily meetings, demo and sprint retrospective',
  ],
  technologies: ['JavaScript', 'TypeScript', 'React', 'redux', 'flux', 'Angular', 'styled-components', 'CSS', 'HTML', 'Node.js', 'PostgreSQL', 'Sequelize', 'Hapi', 'Express', 'AWS S3', 'AWS SQS', 'Rest', 'Postman', 'JWT'],
}, {
  id: 1,
  title: 'Samsung R&D, Kyiv, Ukraine',
  position: 'Software Engineer',
  startDate: new Date(2012, 9, 1),
  endDate: new Date(2014, 6, 15),
  actions: [
    'Had been developing web applications for smartphones, tablets, SmartTVs, etc',
    'Was developing web widgets, fixing bugs, maintaining the code, preparing documentation for the project',
    'Modified existing software to correct errors, upgrade interfaces and improve performance',
    'Consulted regularly with customers on project status, proposals and technical issues',
    'Worked effectively with design team to ensure software solutions elevated client side experience',
  ],
  technologies: ['JavaScript', 'CSS3', 'HTML5', 'jQuery', 'Ember.js', 'Backbone.js'],
}];

export const ExperienceList = () => (<ul>
  {experiences.map(({ id, title, position, startDate, endDate, actions ,technologies }) => <ExperienceItem
    key={id}
    title={title}
    position={position}
    startDate={startDate}
    technologies={technologies}
    endDate={endDate}
    actions={actions} />)}
</ul>);
