import React from 'react';
import { formatDate } from '../../utils/date';

interface ExperienceItemProps {
  title: string;
  position: string;
  startDate: Date;
  endDate?: Date;
  actions: Array<string>;
  technologies: Array<string>;
}

export const ExperienceItem: React.FC<ExperienceItemProps> = ({
  title,
  position,
  startDate,
  endDate,
  actions,
  technologies,
}) => (<li className="mb-4 last-of-type:mb-0">
  <section>
    <h3 className="md:flex justify-between text-lg">
      <span className="block md:inline font-bold">{position} at {title}</span>
      <span className="text-base md:text-lg">
        {`${formatDate(startDate)} — ${endDate ? formatDate(endDate) : 'Now'}`}
      </span>
    </h3>
    <p className="text-sm py-1">{technologies.join(' · ')}</p>
    <ul className="list-disc pl-10">
      {actions.map(item => <li className="text-sm" key={item}>{item}</li>)}
    </ul>
  </section>
</li>);
