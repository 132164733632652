import React from 'react';
import { Header } from './components/header';
import { Main } from './components/main';
import { Footer } from './components/Footer';

export const App = () => (<>
  <Header />
  <Main />
  <Footer />
</>);
