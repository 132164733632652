import React from 'react';
import { Section } from './common/section';
import { ExperienceList } from './experience';
import { Qualifications } from './qualifications';
import { Certifications } from './certifications';
import { Educations } from './educations';
import { Interests } from './interests';

export const Main = () => (<main>
  <Section title="Objective">
    Highly skilled software development professional bringing more than 10 years in software design, development and integration. Advanced knowledge of Javascript and NodeJS. Dynamic software developer skilled at developing complex solutions possessing strong creative thinking skills, high energy and integrity.  Software Engineer who precisely executes development projects from concept to delivery.
  </Section>
  <Section title="Work Experience">
    <ExperienceList />
  </Section>
  <Section title="Qualifications">
    <Qualifications />
  </Section>
  <Section title="Certificates" className="hidden sm:block print:hidden">
    <Certifications />
  </Section>
  <Section title="Education">
    <Educations />
  </Section>
  <Section title="Interests">
    <Interests />
  </Section>
</main>);
