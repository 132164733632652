import React from 'react';
import { Title } from './title';

interface SectionProps {
  children: React.ReactNode;
  title: string;
  className?: string;
}

export const Section: React.FC<SectionProps> = ({ children, title, className }) => (
  <section className={`mt-8${className ? ` ${className}` : ''}`}>
    <Title>{title}</Title>
    <div className="md:pl-8 pt-3 text-sm">{children}</div>
  </section>
);
