import React from 'react';

interface TitleProps {
  children: string;
  className?: string;
}

export const Title: React.FC<TitleProps> = ({ children, className }) => (
  <h2 className={`uppercase text-xl ${ className ? ` ${className}` : '' }`}>
    {children}
  </h2>
);
